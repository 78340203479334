import { FC, Suspense, lazy } from 'react'
import { Link, Route, Router } from 'wouter'
import { About, Home } from '../routes'
import { SentryInit } from './sentry-init'
import './app.css'

SentryInit()

const Base64Decoder = lazy(
  () => import('../routes/base64-decoder/base64-decoder'),
)
const Base64Encoder = lazy(
  () => import('../routes/base64-encoder/base64-encoder'),
)
const ColorPalette = lazy(() => import('../routes/color-palette/color-palette'))
const DataFormatter = lazy(
  () => import('../routes/data-formatter/data-formatter'),
)
const SecretGenerator = lazy(
  () => import('../routes/secret-generator/secret-generator'),
)
const TokenDecoder = lazy(() => import('../routes/token-decoder/token-decoder'))
const URLDecoder = lazy(() => import('../routes/url-decoder/url-decoder'))

export const App: FC = () => {
  return (
    <Router>
      <div className="app">
        <div className="menu">
          <nav>
            <ul className="list">
              <li>
                <Link className="link" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link className="link" to="/about">
                  About
                </Link>
              </li>
              <li>
                <Link className="link" to="/base64-decoder">
                  Base64 Decoder
                </Link>
              </li>
              <li>
                <Link className="link" to="/base64-encoder">
                  Base64 Encoder
                </Link>
              </li>
              <li>
                <Link className="link" to="/data-formatter">
                  Data Formatter
                </Link>
              </li>
              <li>
                <Link className="link" to="/token-decoder">
                  JWT Decoder
                </Link>
              </li>
              <li>
                <Link className="link" to="/secret-generator">
                  Secret Generator
                </Link>
              </li>
              <li>
                <Link className="link" to="/url-decoder">
                  URL Decoder
                </Link>
              </li>
              <li>
                <Link className="link" to="/color-palette">
                  Color Palette
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="styles.page">
          <Route path="/about">
            <Suspense fallback={<div>Loading...</div>}>
              <About />
            </Suspense>
          </Route>
          <Route path="/base64-decoder">
            <Suspense fallback={<div>Loading...</div>}>
              <Base64Decoder />
            </Suspense>
          </Route>

          <Route path="/base64-encoder">
            <Suspense fallback={<div>Loading...</div>}>
              <Base64Encoder />
            </Suspense>
          </Route>

          <Route path="/data-formatter">
            <Suspense fallback={<div>Loading...</div>}>
              <DataFormatter />
            </Suspense>
          </Route>

          <Route path="/token-decoder">
            <Suspense fallback={<div>Loading...</div>}>
              <TokenDecoder />
            </Suspense>
          </Route>

          <Route path="/secret-generator">
            <Suspense fallback={<div>Loading...</div>}>
              <SecretGenerator />
            </Suspense>
          </Route>

          <Route path="/url-decoder">
            <Suspense fallback={<div>Loading...</div>}>
              <URLDecoder />
            </Suspense>
          </Route>

          <Route path="/color-palette">
            <Suspense fallback={<div>Loading...</div>}>
              <ColorPalette />
            </Suspense>
          </Route>

          <Route path="/">
            <Suspense fallback={<div>Loading...</div>}>
              <Home />
            </Suspense>
          </Route>
        </div>
      </div>
    </Router>
  )
}
