import React, { PropsWithChildren } from 'react'
import './page.css'

interface Props {
  title?: string
}

export const Page: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, title } = props
  return (
    <div className="page">
      <h1 className="title">{title}</h1>
      <div className="body">{children}</div>
    </div>
  )
}
